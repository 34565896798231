export const authRole = {
  admin: ['user', 'admin'],
  user: ['user'],
};

export const PAGES_URL = {
  CREATE_CASE: '/cases/create',
  UPDATE_CASE: '/cases/update',
};

export const HidFooterInPages = [PAGES_URL.CREATE_CASE, PAGES_URL.UPDATE_CASE];

export const RoutePermittedRole = {
  admin: 'admin',
  user: 'user',
};

export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};

export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};

export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
  FRAMED: 'framed',
};

export const MenuStyle = {
  DEFAULT: 'default',
  STANDARD: 'standard',
  ROUNDED: 'rounded',
  ROUNDED_REVERSE: 'rounded-reverse',
  CURVED_MENU: 'curved-menu',
};

export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
  MINI_SIDEBAR_TOGGLE: 'mini-sidebar-toggle',
  STANDARD: 'standard',
  HEADER_USER: 'user-header',
  HEADER_USER_MINI: 'user-mini-header',
  DRAWER: 'drawer',
  BIT_BUCKET: 'bit-bucket',
  H_DEFAULT: 'h-default',
  HOR_HEADER_FIXED: 'hor-header-fixed',
  HOR_DARK_LAYOUT: 'hor-dark-layout',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const ThemeDirection = {
  RTL: 'rtl',
  LTR: 'ltr',
};

export enum DaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum NotificationTypeValue {
  CareRequest = 1,
  ClaimBusinessProfileRequest = 2,
}

export const UploadFileType = {
  ProfileImage: 1,
  CareCategory: 2,
  CareSubCategory: 3,
  CareService: 4,
  AdditionalService: 5,
  Skill: 6,
  Certificate: 7,
  Qualification: 8,
  Language: 9,
  CareRecipient: 10,
  Client: 11,
  BookingPayment: 12,
};

export const ScheduleType = {
  regular: 1,
  oneVisit: 2,
  variable: 3,
};

export const CaseStatus = {
  Canceled: 0,
  Draft: 1,
  Submited: 2,
};

export const CaseFileType = {
  ID_Passport: 1,
  UnderTaking: 2,
  DoctorPrescription: 3,
  MedicalReport: 4,
  Other: 5,
};

export const PaymentMethod = {
  OnlinePayment: 1,
  CashToFAN: 2,
  CashToCaregiver: 3,
  BobFinance: 4,
  BankOfBeirut: 5,
};

export const NumberOfHoursOptions = {
  oneHourShift: 1,
  eightHourShift: 8,
  elevenHourShift: 12,
  twentyFourHourShift: 24,
};

export const MarkupType = {
  Consecutive: 1,
  NonConsecutive: 2,
};

export const BookingStatus = {
  draft: 1,
  confirmed: 2,
  active: 3,
  completed: 4,
  cancelled: 5,
  onHold: 6,
};
