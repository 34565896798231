import {createSlice} from '@reduxjs/toolkit';
import {
  OLD_HOURS_OF_SHIFT,
  OLD_PRICING_OF_SHIFT,
  STEPS_NUMBERS,
} from 'src/shared/constants/AppConst';
import {BookingShiftsDto} from 'src/shared/services/booking/dto/booking-shifts-dto';
import {BookingDetailsDto} from 'src/shared/services/booking/dto/details-booking-dto';

interface BookingInitialState {
  bookingDetails: BookingDetailsDto | undefined;
  currentTabInBookingDetails: number;
  isFoundBooking: boolean;
  isFetchShiftsLoading: boolean;
  isFetchBookingShifts: boolean;
  shiftsAllDetails: Array<BookingShiftsDto>;
  bookingShifts: Array<BookingShiftsDto>;
  oldBookingShifts: Array<BookingShiftsDto>;
  selectShiftsAll: boolean;
  refreshPaymentList?: boolean;
  oldPricingOfShift: number;
  oldHoursOfShift: number;
  numberOfShifts: number;
  spanDays: number;
  totalClientCost?: number;
  markupType?: number;
  isShiftsRequired?: boolean;
  isCaregiverAssignmentRequired?: boolean;
}

const initialState: BookingInitialState = {
  bookingDetails: undefined,
  currentTabInBookingDetails: STEPS_NUMBERS.One,
  isFoundBooking: false,
  isFetchShiftsLoading: false,
  isFetchBookingShifts: false,
  shiftsAllDetails: [],
  bookingShifts: [],
  oldBookingShifts: [],
  selectShiftsAll: false,
  refreshPaymentList: false,
  oldPricingOfShift: OLD_PRICING_OF_SHIFT,
  oldHoursOfShift: OLD_HOURS_OF_SHIFT,
  numberOfShifts: 0,
  spanDays: 0,
  totalClientCost: 0,
  markupType: undefined,
  isShiftsRequired: false,
  isCaregiverAssignmentRequired: false,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialState,
  reducers: {
    setBookingDetails: (state, action) => {
      state.bookingDetails = action.payload;
    },
    setCurrentTabInBookingDetails: (state, action) => {
      state.currentTabInBookingDetails = action.payload;
    },
    setIsFoundBooking: (state, action) => {
      state.isFoundBooking = action.payload;
    },
    setIsFetchShiftsLoading: (state, action) => {
      state.isFetchShiftsLoading = action.payload;
    },
    setIsFetchBookingShifts: (state, action) => {
      state.isFetchBookingShifts = action.payload;
    },
    setShiftsAllDetails: (state, action) => {
      state.shiftsAllDetails = action.payload;
    },
    setBookingShifts: (state, action) => {
      state.bookingShifts = action.payload;
    },
    setOldBookingShifts: (state, action) => {
      state.oldBookingShifts = action.payload;
    },
    setSelectShiftsAll: (state, action) => {
      state.selectShiftsAll = action.payload;
    },
    setRefreshPaymentList: (state, action) => {
      state.refreshPaymentList = action.payload;
    },
    setOldPricingOfShift: (state, action) => {
      state.oldPricingOfShift = action.payload;
    },
    setOldHoursOfShift: (state, action) => {
      state.oldHoursOfShift = action.payload;
    },
    setNumberOfShifts: (state, action) => {
      state.numberOfShifts = action.payload;
    },
    setSpanDays: (state, action) => {
      state.spanDays = action.payload;
    },
    setTotalClientCost: (state, action) => {
      state.totalClientCost = action.payload;
    },
    setMarkupType: (state, action) => {
      state.markupType = action.payload;
    },
    setIsShiftsRequired: (state, action) => {
      state.isShiftsRequired = action.payload;
    },
    setIsCaregiverAssignmentRequired: (state, action) => {
      state.isCaregiverAssignmentRequired = action.payload;
    },
  },
});

export const {
  setBookingDetails,
  setCurrentTabInBookingDetails,
  setIsFoundBooking,
  setIsFetchShiftsLoading,
  setIsFetchBookingShifts,
  setShiftsAllDetails,
  setBookingShifts,
  setOldBookingShifts,
  setSelectShiftsAll,
  setRefreshPaymentList,
  setOldPricingOfShift,
  setOldHoursOfShift,
  setNumberOfShifts,
  setSpanDays,
  setTotalClientCost,
  setMarkupType,
  setIsShiftsRequired,
  setIsCaregiverAssignmentRequired,
} = bookingSlice.actions;

export default bookingSlice.reducer;
